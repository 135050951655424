















import {Component, Prop, Vue} from 'vue-property-decorator';
import ColorPicker from "@/components/global/ColorPicker.vue";
import MoodTypeSettings from "@/models/MoodTypeSettings";
import MoodTypeSetting from "@/models/MoodTypeSetting";

@Component({components: {ColorPicker}})
export default class DisplayTypeThumb extends Vue {

    @Prop({required:true, default: new MoodTypeSettings()})
    private value!:MoodTypeSettings;

    private get moodFour() : MoodTypeSetting {
        if(this.value && this.value.four) {
            return this.value.four;
        }
        return new MoodTypeSetting();
    }
    private get moodTwo() : MoodTypeSetting {
        if(this.value && this.value.two) {
            return this.value.two;
        }
        return new MoodTypeSetting();
    }
}
